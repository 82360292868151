import carSteering from '../../assets/viewSeats/car-steering.jpg';
import sleeperSeat from '../../assets/viewSeats/03.png';
import ladiesSeat from '../../assets/viewSeats/02.png';
import available from '../../assets/viewSeats/04.png';
import selected from '../../assets/viewSeats/01.png';
import { Tooltip } from "@mui/material";
import './ViewSeats.css';
import { useEffect, useState } from 'react';
import { Card, Tab, Tabs, RadioGroup, FormControlLabel, Radio, Button } from '@material-ui/core';
import swal from 'sweetalert2';

const ViewSeats = () => {
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [boarding, setBoarding] = useState('');
    const [dropping, setDropping] = useState('');
    const [showProcceedToPay, setShowProcceedToPay] = useState(false);
    const [showBoarding, setShowBoarding] = useState(false);

    const handleDropping = (event) => setDropping(event.target.value);


    const handleBoarding = (event) => {
        setBoarding(event.target.value);
        setTimeout(() => {
            setTabValue(1);
        }, 500)
    }


    useEffect(() => {
        console.log(selectedSeats);
    }, [selectedSeats]);

    const handleTab = (event, newValue) => {
        setTabValue(newValue);
    }

    const seatAlert = (seatNo) => {
        swal.fire({
            icon: 'error',
            title: 'Bus Seat',
            text: `Seat No: ${seatNo} this seat Blocked  for upcoming 11 minutes  !`,
            // footer: 'OK'
        })
    }

    const handleContinue = () => {
        if (boarding === '' || dropping === '') {
            swal.fire({
                icon: 'warning',
                title: 'Boarding & Dropping Points',
                text: 'Please select boarding and dropping points'
            })
        } else {
            setShowProcceedToPay(true)
            setShowBoarding(false)
        }
    }

    const handleSelect = (value) => {
        console.log(showBoarding, selectedSeats && selectedSeats.length)
        if (selectedSeats.includes(value)) {
            let updateSeats = selectedSeats.filter(item => item !== value);
            setSelectedSeats(updateSeats);
        } else {
            setSelectedSeats([...selectedSeats, value]);
            showProcceedToPay === false && setShowBoarding(true);
        }
        
    }

    const handleChange = () => {
        setShowBoarding(true);
        setShowProcceedToPay(false);
        setDropping('')
    }
    
    var price = selectedSeats && selectedSeats.length > 0 && selectedSeats.length * 999;
    return (
        <div>
            <div className='div-break'></div>
            <div className='view-seats-block mt-15'>
                <div className='seats-block seat-layout'>
                    <h4 className='text-left'>
                        <strong>Lower Deck</strong>
                    </h4>
                    <div className='lower-deck-block mb-50'>
                        <div className='border-right border-left-dark'>
                            <img className='steering-img' src={carSteering} alt />
                        </div>
                        <div className='child-2'>
                            <div className='upper-seat'>
                                <div>
                                    <div className='seat'>
                                        <Tooltip title='Seat No: 1/Price: ₹999.00'>
                                            <button className='right_seat' onClick={() => seatAlert(1)}>
                                                <img src={sleeperSeat} className='sleeperImage' alt />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div className='seat'>
                                        <Tooltip title='Seat No: 5/Price: ₹999.00'>
                                            <button className='right_seat' onClick={() => seatAlert(5)}>
                                                <img src={ladiesSeat} className='sleeperImage' alt />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div className='seat'>
                                        <Tooltip title='Seat No: 6/Price: ₹999.00'>
                                            <button className='right_seat' onClick={() => seatAlert(6)}>
                                                <img src={ladiesSeat} className='sleeperImage' alt />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div className='seat'>
                                        <Tooltip title='Seat No: 7/Price: ₹999.00'>
                                            <button className='right_seat' onClick={() => handleSelect(7)}>
                                                <img src={selectedSeats.includes(7) ? selected : available} className='sleeperImage' alt />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div>
                                    <div className='seat'>
                                        <Tooltip title='Seat No: 8/Price: ₹999.00'>
                                            <button className='right_seat' onClick={() => handleSelect(8)}>
                                                <img src={selectedSeats.includes(8) ? selected : available} className='sleeperImage' alt />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div className='lower-seat-block'>
                                <div className='lower-seat'>
                                    <div>
                                        <div className='seat' >
                                            <Tooltip title='Seat No: 9/Price: ₹999.00'>
                                                <button className='right_seat' onClick={() => handleSelect(9)}>
                                                    <img src={selectedSeats.includes(9) ? selected : available} className='sleeperImage' alt />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='seat'>
                                            <Tooltip title='Seat No: 11/Price: ₹999.00'>
                                                <button className='right_seat' onClick={() => handleSelect(11)}>
                                                    <img src={selectedSeats.includes(11) ? selected : available} className='sleeperImage' alt />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='seat'>
                                            <Tooltip title='Seat No: 13/Price: ₹999.00'>
                                                <button className='right_seat' onClick={() => handleSelect(13)}>
                                                    <img src={selectedSeats.includes(13) ? selected : available} className='sleeperImage' alt />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='seat'>
                                            <Tooltip title='Seat No: 14/Price: ₹999.00'>
                                                <button className='right_seat' onClick={() => handleSelect(14)}>
                                                    <img src={selectedSeats.includes(14) ? selected : available} className='sleeperImage' alt />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='seat'>
                                            <Tooltip title='Seat No: 15/Price: ₹999.00'>
                                                <button className='right_seat' onClick={() => handleSelect(15)}>
                                                    <img src={selectedSeats.includes(15) ? selected : available} className='sleeperImage' alt />
                                                </button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='view-seat-right p-10'>
                    <h4 className='text-left mt-0'>
                        <strong>SEAT LEGEND</strong>
                    </h4>
                    <div className='seat-available-block'>
                        <div className='empty-border'></div>
                        <small className='mr-12'>Available</small>
                        <div className='bg-border'></div>
                        <small className='mr-12'>Unavailable</small>
                        <div className='bg-selected'></div>
                        <small className='mr-12'>Selected</small>
                        <div className='bg-ladies'></div>
                        <small className='mr-12'>Ladies</small>
                    </div>

                    {
                        showBoarding === true &&
                        <Card className='seat-boarding'>
                            <Tabs value={tabValue} onChange={handleTab} indicatorColor='red'>
                                <Tab label='BOARDING POINT' />
                                <Tab label='DROPPING POINT' />
                            </Tabs>
                            {
                                tabValue === 0 &&
                                <div className='tabPanel'>
                                    <div className='tab-body'>
                                        <div className='radio-group-boarding'>
                                            <RadioGroup onClick={handleBoarding} value={boarding}>
                                                <FormControlLabel
                                                    control={<Radio />}
                                                    value='21:00 Majestic'
                                                    label={
                                                        <div className='radio-label'>
                                                            <h4 className='mr-15'>
                                                                <strong>21:00</strong>
                                                            </h4>
                                                            <p className='m-10-5'>Majestic</p>
                                                        </div>

                                                    } />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                tabValue === 1 &&
                                <div className='tabPanel'>
                                    <div className='tab-body'>
                                        <div className='radio-group-boarding'>
                                            <RadioGroup onChange={handleDropping} value={dropping}>
                                                <FormControlLabel
                                                    control={<Radio />}
                                                    value='06:00 Koyambedu'
                                                    label={
                                                        <div className='radio-label'>
                                                            <h4 className='mr-15'>
                                                                <strong>06:00</strong>
                                                            </h4>
                                                            <p className='m-10-5'>Koyambedu</p>
                                                        </div>

                                                    } />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='bottom-block p-10'>
                                <hr />
                                <p className='text-center'>
                                    Amount
                                    <small>(Taxes will be calculated during payment) </small>
                                    <strong>₹ {price}.00</strong>
                                </p>
                                <button className='w100 continue-btn' type='button' onClick={handleContinue}>CONTINUE</button>
                            </div>
                        </Card>
                    }

                    {
                        showProcceedToPay === true &&
                        <Card className='pay-block p-10 txt-color'>
                            <div className='inner-pay-block'>
                                <div className='full-block'>
                                    <div className='inner-pay-block p-10'>
                                        <div className='half-block'>
                                            <h3 className='mt-0'>
                                                <strong>Boarding & Dropping</strong>
                                            </h3>
                                        </div>
                                        <div className='half-block align-right'>
                                            <a className='mt-7 mb-7' onClick={handleChange}><strong>CHANGE</strong></a>
                                        </div>
                                        <div className='half-block light-color-text'>
                                            <p className='mb-3 mt-0 f16'>Majestic</p>
                                            <p className='mb-3 mt-0 f16'></p>
                                        </div>
                                        <div className='half-block align-right'>
                                            <p className='mt-0 mb-0'>
                                                <strong>21:00</strong>
                                            </p>
                                        </div>
                                        <div className='half-block light-color-text'>
                                            <p className='mb-3 mt-0 f16'>Koyambedu</p>
                                        </div>
                                        <div className='half-block align-right'>
                                            <p className='mt-0 mb-0'>
                                                <strong>06:00</strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='border-top mt-7'>
                                    </div>
                                    <div className='inner-pay-block p-10 mt-7'>
                                        <div className='half-block light-color-text'>
                                            <p className='mb-0 mt-0'>
                                                <strong>Seat No.</strong>
                                            </p>
                                        </div>
                                        <div className='half-block align-right'>
                                            <p className='mb-0 mt-0'>
                                                <strong>{selectedSeats.join(', ')}</strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='border-top mt-15'>
                                    </div>
                                    <h3 className='mb-0 mt-0 p-10'>
                                        <strong>Fare Details</strong>
                                    </h3>
                                    <div className='inner-pay-block p-10'>
                                        <div className='half-block light-color-text'>
                                            <p className='mb-0 mt-0'>
                                                <strong>Basic Fare</strong>
                                            </p>
                                        </div>
                                        <div className='half-block align-right'>
                                            <p className='mb-0 mt-0'>
                                                <strong>₹ {price}.0</strong>
                                            </p>
                                        </div>
                                    </div>
                                    <div className='inner-pay-block p-10 mb-7'>
                                        <div className='tot-amt light-color-text'>
                                            <p className='mb-0 mt-0'>
                                                Total Amount
                                            </p>
                                            <small>Taxes will be calculated during payment</small>
                                        </div>
                                        <div className='tot-amt-sec align-right'>
                                            <p className='mb-0 mt-0'>
                                                <strong>₹ {price}.0</strong>
                                            </p>
                                        </div>
                                    </div>
                                    <button className='w100 continue-btn' type='button'>PROCEED TO PAY</button>
                                </div>
                            </div>
                        </Card>

                    }


                </div>
            </div>
        </div>
    )
}

export default ViewSeats;