import './Features.css'
import t24 from '../../assets/features/24-1.png';
import assurance from '../../assets/features/assurance-1.png';
import main from '../../assets/features/new-main.png';
import offer from '../../assets/features/offer-1.png';
import refund from '../../assets/features/refund-1.png';
import secure from '../../assets/features/safe_secure-1.png';
import track from '../../assets/features/track-1.png';

const Features = () => {
    return (
        <div className='features'>
            <div className='features-center'>
                <div className='features-text'>
                    <h2 className='features-heading'>Features</h2>
                </div>
                <div className='features-content features-mb-40'>
                    <div className='left-content'>
                        <div className='left-side-content'>
                            <div className='left-side-content-1 features-inner'>
                                <img src={secure} alt className='icons' />
                                <h3>Safe and Secure</h3>
                            </div>
                            <div className='left-side-content-1 features-inner'>
                                <img src={t24} alt className='icons' />
                                <h3>24/7 Customer Support</h3>
                            </div>
                            <div className='left-side-content-1 features-inner'>
                                <img src={track} alt className='icons' />
                                <h3>Track Your Bus Location</h3>
                            </div>
                        </div>

                    </div>
                    <div className='center-content'>
                        <div className='center-text-content text-center'>
                            <img src={main} alt className='w-75' />
                            <h3>Book bus tickets online in website and from your mobile with few easy steps</h3>
                        </div>
                    </div>
                    <div className='right-content'>
                        <div className='right-side-content ml-20'>
                        <div className='right-side-content-1 features-inner'>
                                <img src={refund} alt className='icons' />
                                <h3>Instant Refund</h3>
                            </div>
                            <div className='right-side-content-1 features-inner'>
                                <img src={assurance} alt className='icons' />
                                <h3>Alternate Assurance</h3>
                            </div>
                            <div className='right-side-content-1 features-inner'>
                                <img src={offer} alt className='icons' />
                                <h3>Exciting Offers</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features;