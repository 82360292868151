import './Banner.css';
import flower from '../../assets/flower-11.png';
import offerBadge from '../../assets/offer-badge-11.png';
import debitCard from '../../assets/debit_card.png';
import mouse from '../../assets/mouse-11.png';
import upi from '../../assets/upi-11.png';
import redBus from '../../assets/redbus.png';
import { Button } from '@material-ui/core';


const Banner = () => {
    return (
        <div className='banner-offer-section '>
            <div className='banner-sec mt-50 m-20'>
                <div className='banner-div1 mtop-40 banner-mb-40'>
                    <img src={flower} alt className='flower' />
                </div>
                <div className='banner-center mt-13 mb-13'>
                    <div className='text-center'>
                        <div className='banner-center-content'>
                            <img src={offerBadge} alt className='offer' />
                            <h5>GET OFFER</h5>
                            <h2>5% & MORE</h2>
                            <div className='promo-code'>
                                <div className='input-promo'>
                                    <p>PROMO-CODE</p>
                                </div>
                                <Button className='pro-btn'>BNNEW</Button>
                            </div>
                            <h4>Pay with</h4>
                            <div className='banner-center-sec-content-1'>
                                <div className='debcard pr-35'>
                                    <img src={debitCard} alt />
                                    <p>Credit / Debit card </p>
                                </div>
                                <div className='netbank pr-20'>
                                    <img src={mouse} alt/>
                                    <p>Net Banking </p>
                                </div>
                                <div className='upi-1'>
                                    <img src={upi} alt />
                                    <p>UPI</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='banner-right'>
                    <img src={redBus} alt />
                </div>
            </div>

        </div>
    )
}

export default Banner;