import './Aboutus.css';
import { Card, CardContent } from '@material-ui/core';

const Aboutus = () => {
    return (
        <div className="aboutus-section aboutus" >
            <div className='about-us-detail aboutus-content'>
                <Card className='mar-card'>
                    <div className='mat-card-title'>
                        Who We Are , What We Do
                    </div>
                    <CardContent>
                        <p className='text-justify mt-25'>
                        Busnow is an leading online Ticketing platform in india providing 
                        a 'best in class' customer experience with the goal that helps you 
                        organise, book and track your trips. With busnow, get to know real-time 
                        prices & availability for seats in buses. So what are you waiting for? 
                        If you aren’t already among the millions of travellers who have chosen to 
                        simplify their lives, then it’s time to come aboard the busnow experience. 
                        </p>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default Aboutus;