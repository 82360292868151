import './App.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import Menubar from './Components/Menubar/Menubar';
import HomePage from './Components/HomePage/Homepage';
import Aboutus from './Components/Aboutus/Aboutus';
import BookingList from './Components/BookingList/BookingList';

const App = () => {
  return (
    <BrowserRouter>
      <Menubar />
      <Switch>
        <Route path="/" component={HomePage} exact />
        <Route path="/about-us" component={Aboutus} exact />
        <Route path='/booking-list' component={BookingList} exact />
      </Switch>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
