import './Footer.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <div className='footer'>
            <div className="footer-block">
                <div className='footer-inner'>
                    <div className='footer-div1'></div>
                    <div className='footer-div'>
                        <h3>Company</h3>
                        <a href='/about-us'>About Us</a>
                        <a href='#'>Contact Us</a>
                        <a href='#'>Terms & Conditions</a>
                    </div>
                    <div className='footer-div2'>
                        <h3>Info</h3>
                        <a href='#'>Privacy Policy</a>
                        <a href='#'>Cancellation & Refund Policy</a>
                        <a href='#'>FAQ</a>
                    </div>
                    <div className='footer-div'>
                        <p>
                            Busnow is the India's Best Online Bus Ticket Booking Service.
                            We offers bus ticket booking through website ,
                            iOS and Android mobile apps for all major routes.
                        </p>
                        <div className='social-media'>
                            <a href='#'>
                                <FontAwesomeIcon icon={faFacebookF} color='#fff' className='fa' />
                            </a>
                            <a href='#'>
                                <FontAwesomeIcon icon={faTwitter} color='#fff' className='fa' />
                            </a>
                            <a href='#'>
                                <FontAwesomeIcon icon={faLinkedinIn} color='#fff' className='fa' />
                            </a>
                            <a href='#'>
                                <FontAwesomeIcon icon={faInstagram} color='#fff' className='fa' />
                            </a>

                        </div>

                    </div>
                </div>

            </div>
            <div className='bottom-footer-block'>
                <p className='copy-right-color'>
                    Copyright ©2022 AVJ Online Services (OPC) Pvt Ltd.All Rights Reserved.
                </p>

            </div>
        </div>
    )
}

export default Footer;