import { useState } from 'react';
import man from '../../assets/man.png';
import flower from '../../assets/flower.png';
import sittingMan from '../../assets/sitting-man.png';
import camera from '../../assets/camera.png';
import './SearchForm.css';
import { CardMedia, Card, makeStyles, TextField, Button } from '@material-ui/core';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const useStyles = makeStyles((theme) => ({
    root: {
        transition: 'box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1)',
        display: 'block',
        position: 'initial',
        borderRadius: '4px',
        '&:before': {
            content: `""`,
            width: 0,
            height: 0,
            position: 'absolute',
            bottom: '98%',
            right: '0px',
            border: '11px solid #502b70',
            borderRadius: '434px 137px 86px 74px'
        },
    },

}));

const SearchForm = () => {
    const classes = useStyles();
    const [values, setValues] = useState({
        fromCity: "",
        toCity: "",
        departureDate: ""
    });



    const handleChange =  (event) => {

        setValues({ ...values, [event.target.name]: event.target.value });
    };

    
    return (
        <div className='bookbus-tickets'>
            <div className='div1 text-center text-center-responsive'>
                <div className='ptext'>
                    <h2 className='text-primary mb-5 fnt-30'>
                        <b>On the places You"ll Go..</b>
                    </h2>
                    <h4 className='mb-40 mt-0 fn-20'>
                        We care on your journey...
                    </h4>
                    <img src={flower} alt className='img-padding' />
                    <img src={sittingMan} alt className='vertical-align' />
                    <img src={camera} alt />
                </div>
            </div>
            <div className='div2 mt-20'>
                <div className='test-block'>
                    <Card className='bus-ticket-block' classes={{ root: classes.root }}  >
                        <h4>Book Bus Ticket</h4>
                        <form className='form-block'>
                            <div className='inner-div1'>
                                <div className='inner-div2'>
                                    <TextField
                                        label="From"
                                        name='fromCity'
                                        // value=''
                                        onChange={(e)=>handleChange(e)}
                                        variant="outlined"
                                        // error={values.fromCity== ""}
                                        // helperText={values.fromCity== "" ? 'Please select the valid from city' : ''}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className='inner-div1'>
                                <div className='inner-div2'>
                                    <TextField
                                        label="To"
                                        name='toCity'
                                        onChange={(e)=>handleChange(e)}
                                        variant="outlined"
                                        // error={values.toCity== ""}
                                        // helperText={values.toCity== "" ? 'Please select the valid to city' : ''}
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className='inner-div1'>
                                <div className='inner-div2'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Departure Date"
                                            value={values.departureDate}
                                            onChange={(newValue) => {
                                            setValues({ ...values, ['departureDate']: newValue });
                                            }}
                                            renderInput={(params) => <TextField {...params} 
                                            variant="outlined" 
                                            // error="Please select the departure date" 
                                            required={true} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </div>
                            <div className='text-center mb-40'>
                                <Button type="submit" className='search-btn1' href='/booking-list'>
                                    <span>
                                        Search
                                        <ArrowRightIcon className='search-icon' />
                                    </span>
                                </Button>
                            </div>
                        </form>

                    </Card>
                </div>
            </div>
            <div className='div3 bg-primary'>
                <CardMedia component='img' image={man} />
            </div>
           
        </div>
    )
}

export default SearchForm;