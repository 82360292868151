import { Dialog, Button, Tab, Tabs, Box, TextField, MenuItem, Checkbox } from '@material-ui/core';
import { useState, useEffect } from 'react';
import login from '../../assets/Dialog/login-bg.png';
import close from '../../assets/Dialog/close-icon.png';
import './Signup.css';


const Signup = (props) => {
    const { handleOpen, handleClose, tabIndex } = props;
    
    const [activeTabIndex, setActiveTabIndex] = useState(tabIndex)
    // console.log(tabIndex, activeTabIndex);
    
    useEffect(()=> {
        return () => {
            setActiveTabIndex(tabIndex)
        }
    }, [])
    
    const handleTabIndex = (event, index) => {
        // console.log(index)
        setActiveTabIndex(index);
    }



    return (
        <div>
            <div>
                <Dialog open={handleOpen} onClose={handleClose} TransitionProps='fade' className='signup-block' fullWidth={true}>
                    <Box className='signup-box'>
                        <div className='first-block'>
                            <div className='list-item-head'>
                                <Tabs value={activeTabIndex}
                                    onChange={handleTabIndex}
                                >
                                    <Tab label="Sign In" />
                                    <Tab label="Sign Up" />
                                </Tabs>
                            </div>
                            <Box>
                                {
                                    activeTabIndex == 0 &&
                                    <div className='display-Content'>
                                        <form>
                                            <div className='test-function'>
                                                <div className='list-add seltest'>
                                                    <label>
                                                        Mobile Number
                                                    </label>
                                                    <TextField
                                                        variant='outlined'
                                                        placeholder='Phone Number'
                                                        className='form-field'
                                                    />
                                                </div>
                                                <div className="signin-function">
                                                    <button>Generate OTP</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                }
                                {
                                    activeTabIndex == 1 &&
                                    <div className='main-container'>
                                        <form>
                                            <div className='list-item-first'>
                                                <div className='input-content'>
                                                    <label>Name</label>
                                                    <TextField
                                                        variant='outlined'
                                                        placeholder='First name'
                                                        className='form-field'
                                                    />
                                                </div>
                                                <div className='input-content-list'>
                                                    <label>empty</label>
                                                    <TextField
                                                        variant='outlined'
                                                        placeholder='Last name'
                                                        className='form-field'
                                                    />
                                                </div>
                                            </div>
                                            <div className='list-item-first list-content'>
                                                <div className='input-content'>
                                                    <label>Phone</label>
                                                    <TextField
                                                        variant='outlined'
                                                        placeholder='Phone Number'
                                                        className='form-field'
                                                    />
                                                </div>
                                                <div className='input-content-list function-new'>
                                                    <label>E-mail</label>
                                                    <TextField
                                                        variant='outlined'
                                                        placeholder='E-mail'
                                                        className='form-field'
                                                    />
                                                </div>
                                            </div>
                                            <div className='select-sec'>
                                                <label>Gender</label>
                                                <TextField
                                                    variant='outlined'
                                                    select
                                                    className='form-field'
                                                >
                                                    <MenuItem value={'male'}>Male</MenuItem>
                                                    <MenuItem value={'female'}>Female</MenuItem>
                                                </TextField>
                                            </div>
                                            <div className='boxes'>
                                                <Checkbox />
                                                <span>I agrees
                                                    <b>
                                                        <a href='#'> Terms & Conditions </a>
                                                    </b>
                                                    and
                                                    <b>
                                                        <a href='#'> Privacy Policy </a>
                                                    </b>
                                                </span>
                                            </div>
                                            <div className='last-sec'>
                                                <button type='submit'>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                }
                            </Box>
                        </div>
                        <div className='second-block banner-block banner'>
                            <img src={close} className='card-img' alt onClick={handleClose} />
                            <img src={login} className='bgcard-image' alt />

                        </div>
                    </Box>
                </Dialog>
            </div>

        </div>
    )
}

export default Signup;