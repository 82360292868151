import { Box, Dialog, Card, CardContent, CardHeader, IconButton } from "@material-ui/core";
import ClearIcon from '@mui/icons-material/Clear';
import './FeaturesPopup.css';

const FeaturesPopup = (props) => {
    const { open, handleClose, title, data } = props;
    
    return (
        <div className="features-popup">
            <Dialog open={open} onClose={handleClose}>
                <Card className="popup p-10">
                    <CardHeader className=".card-title-text p-10 card-bg-primary"
                        title={title}
                        action={
                            <IconButton className="close-button">
                                <ClearIcon onClick={handleClose} />
                            </IconButton>
                        }
                    />
                    <CardContent>

                        {
                            title === 'Amenities' &&
                            <div className="content-block">
                                <div className="mb-15 amenities-block bullet-list">
                                    <ui>
                                        {
                                            data && data.amenities && data.amenities.data && data.amenities.data.length > 0 &&
                                            data.amenities.data.map((item) => {
                                                // console.log(item)
                                                return (
                                                    <li className="item">{item}</li>
                                                )
                                            })
                                        }

                                        {
                                            data && data.amenities.length === 0 &&
                                            <p>No Data Found</p>
                                        }

                                    </ui>
                                </div>
                            </div>
                        }
                        {
                            title === 'Boarding - Dropping Points' &&
                            <div className="content-block">
                                <div className="boarding-block">
                                    {
                                        data && data.boardingDropping && data.boardingDropping.length === 0 &&
                                        <div className="inner-board">
                                            <p>No data Found</p>
                                        </div>
                                    }
                                    {
                                        data && data.boardingDropping && data.boardingDropping.data && data.boardingDropping.data.length > 0 &&
                                        <div className="inner-board">
                                            <div className="dropping-block mb-15">
                                                <h4 className="txt-muted"><u>BOARDING POINT</u></h4>
                                                <div className="bullet-list">
                                                    <ul>
                                                        {
                                                            data.boardingDropping.data.map((item) => {
                                                                return (
                                                                    <li>
                                                                        <span>
                                                                            <b>{item.boardingTime}</b>
                                                                        </span>
                                                                        <span className="txt-overflow">
                                                                            {item.boardingPoint}
                                                                        </span>
                                                                    </li>
                                                                )
                                                            })
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="dropping-block mb-15">
                                                <h4 className="txt-muted"><u>DROPPPING POINT</u></h4>
                                                <div className="bullet-list">
                                                    <ul>
                                                        {
                                                            data && data.boardingDropping && data.boardingDropping.data && data.boardingDropping.data.length > 0 &&
                                                            data.boardingDropping.data.map((item) => {
                                                                return (
                                                                    <li>
                                                                        <span>
                                                                            <b>{item.droppingTime}</b>
                                                                        </span>
                                                                        <span className="txt-overflow">
                                                                            {item.droppingPoint}
                                                                        </span>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                        }
                        {
                            title === 'Customer Reviews' &&
                            <div className="content-block">
                                <div className="mb-15 reviews-block">
                                    {
                                        data && data.reviews.data.length === 0 &&
                                        <div className="text-center">No Data Found</div>
                                    }
                                </div>
                            </div>
                        }
                        {
                            title === 'Cancellation Policy' &&
                            <div className="content-block">
                                <div className="cancel-policy">
                                    {
                                        data && data.cancellationPolicy.data.length === 0 &&
                                        <div className="text-center">No Data Found</div>
                                    }
                                </div>
                            </div>
                        }
                    </CardContent>
                </Card>
            </Dialog>
        </div>
    )
}

export default FeaturesPopup;