import { List, ListItem, Checkbox, Slider, TextField, Button, Card, CardContent } from "@material-ui/core";
import { useState } from "react";
import './BookingList.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import round from '../../assets/booking-list/round_32.png';
import map from '../../assets/booking-list/map_32.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StarRateIcon from '@mui/icons-material/StarRate';
import FeaturesPopup from "../FeaturesPopup/FeaturesPopup";
import ViewSeats from "../ViewSeats/ViewSeats";

const dummyData = {
    amenities: {
        data: ['Hand Book', 'Water Bottle', 'Bed Sheet']
    },
    boardingDropping: {
        data: [
            {
                boardingTime: '21: 00 pm',
                boardingPoint: 'Mejestic',
                droppingTime: '06: 00 am',
                droppingPoint: 'Koyambedu'
            }
        ]
    },
    reviews: {
        data: []
    },
    cancellationPolicy: {
        data: []
    }
}

const BookingList = () => {
    const [value, setValue] = useState([0, 10000]);
    const [show, setShow] = useState(false);
    const [date, setDate] = useState('01/30/2023');
    const [open, setOpen] = useState(false);
    const [featureTitle, setFeatureTitle] = useState('Amenities');
    const [showSeats, setShowSeats] = useState(false);

    const handleDialogOpen = (value) => {
        setOpen(true);
        setFeatureTitle(value);
    }

    const handleDialogClose = () => setOpen(false);

    const handleChange = (value) => {
        setValue(value)
    }

    const valuetext = (value) => {
        return `${value}`;
    }

    const handleOpen = () => setShow(true);

    const handleClose = () => setShow(false);

    const handleOpenSeats = () => setShowSeats(true);

    const handleCloseSeats = () => setShowSeats(false);

    return (
        <div className="booking-list">
            <div className="booking-list-sec txt-color">
                <div className="navbar category-list">
                    <div className="departure-block">
                        <div>
                            <h4 className="left-block-heading">BUS CATEGORY</h4>
                        </div>
                        <List className="list-base">
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>AC</span>
                                </div>
                            </ListItem>
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>Seater</span>
                                </div>
                            </ListItem>
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>Sleeper</span>
                                </div>
                            </ListItem>
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>Semi Sleeper</span>
                                </div>
                            </ListItem>
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>Seat cum Sleeper</span>
                                </div>
                            </ListItem>
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>All</span>
                                </div>
                            </ListItem>
                        </List>
                    </div>
                    <div className="departure-block">
                        <div>
                            <h4 className="left-block-heading">Price Range</h4>
                        </div>
                        <div className="slider">
                            <p className="slider-0">0</p>
                            <Slider value={value} onChange={handleChange} className='slider-hide' />
                            <Slider
                                min={0}
                                max={10000}
                                valueLabelDisplay='auto'
                                onChange={handleChange}
                                // marks
                                defaultValue={[0, 10000]}
                                getAriaValueText={valuetext}
                                className='slider-area mr-12'
                                disableSwap
                            />
                            <p className="slider-10000">10,000</p>
                        </div>
                    </div>
                    <div className="departure-block">
                        <div>
                            <h4 className="left-block-heading">DEPARTURE TIME</h4>
                        </div>
                        <List className="list-base">
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>Before 6 am</span>
                                </div>
                            </ListItem>
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>6 am to 12 pm</span>
                                </div>
                            </ListItem>
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>12 pm to 6 pm</span>
                                </div>
                            </ListItem>
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>After 6 pm</span>
                                </div>
                            </ListItem>
                        </List>
                    </div>
                    <div className="departure-block">
                        <div>
                            <h4 className="left-block-heading">ARRIVAL TIME</h4>
                        </div>
                        <List className="list-base">
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>Before 6 am</span>
                                </div>
                            </ListItem>
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>6 am to 12 pm</span>
                                </div>
                            </ListItem>
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>12 pm to 6 pm</span>
                                </div>
                            </ListItem>
                            <ListItem disableGutters>
                                <div className="list-item-content">
                                    <Checkbox />
                                    <span>After 6 pm</span>
                                </div>
                            </ListItem>
                        </List>
                    </div>
                </div>
                <div className="buslit-right right-side-block">
                    {
                        show && <div>
                            <div className="buslist-search mb-15 mr-15 mt-5">
                                <div className="inner-search">

                                    <form className="bus-search">
                                        <div className="from-area mr-15">
                                            <TextField
                                                label='From'
                                                required
                                                variant="standard"
                                            // value='Madurai'
                                            />
                                        </div>
                                        <div className="from-area mr-15">
                                            <TextField
                                                label='To'
                                                required
                                                variant="standard"
                                            // value='Madurai'
                                            />
                                        </div>
                                        <div className="from-area mr-15">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Departure Date"
                                                    value={date}
                                                    onChange={(newValue) => {
                                                        setDate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params}
                                                        variant="standard"
                                                    // required={true} 
                                                    />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <div className="from-area form-button-area text-right mt-15">
                                            <Button className="viewbus-btn">Search</Button>
                                        </div>
                                    </form>
                                </div>
                                <div className="empty-div mt-15"></div>
                                <div className="close-button mt-15">
                                    <Button className="viewbus-btn" onClick={handleClose}>Close</Button>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        show === false && <div>
                            <div className="buslist-search mb-15 mr-15 mt-5">
                                <div className="sec-empty-div"></div>
                                <div className="show-date mt-15 text-right">
                                    <KeyboardArrowLeftIcon className="mat-buslist-icon" />
                                    <span className="date-display">30, Jan</span>
                                    <KeyboardArrowRightIcon className="mat-buslist-icon" />
                                </div>
                                <div className="close-button mt-15">
                                    <Button className="viewbus-btn" onClick={handleOpen}>Change</Button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="light-grey">
                        <div className="bus-details sorting-options txt-color">
                            <div className="bus-count">
                                <label>
                                    <strong>1 Bus</strong> Found
                                </label>
                            </div>
                            <div className="sort">
                                <label>
                                    <strong>Sort By : </strong>
                                </label>
                            </div>
                            <div className="sort-departure">
                                <div className="mat-sort-header-container">
                                    <button className="mat-sort-header-button">
                                        <a>Departure</a>
                                        <Checkbox
                                            icon={<NorthIcon />}
                                            checkedIcon={<SouthIcon />}
                                            className="mat-sort-header-arrow"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="sort-departure">
                                <div className="mat-sort-header-container">
                                    <button className="mat-sort-header-button">
                                        <a>Duration</a>
                                        <Checkbox
                                            icon={<NorthIcon />}
                                            checkedIcon={<SouthIcon />}
                                            className="mat-sort-header-arrow"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="sort-departure">
                                <div className="mat-sort-header-container">
                                    <button className="mat-sort-header-button">
                                        <a>Arrival</a>
                                        <Checkbox
                                            icon={<NorthIcon />}
                                            checkedIcon={<SouthIcon />}
                                            className="mat-sort-header-arrow"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="sort-departure">
                                <div className="mat-sort-header-container">
                                    <button className="mat-sort-header-button">
                                        <a>Fare</a>
                                        <Checkbox
                                            icon={<NorthIcon />}
                                            checkedIcon={<SouthIcon />}
                                            className="mat-sort-header-arrow"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="rating">
                                <div className="mat-sort-header-container">
                                    <button className="mat-sort-header-button">
                                        <a>Rating</a>
                                        <Checkbox
                                            icon={<NorthIcon />}
                                            checkedIcon={<SouthIcon />}
                                            className="mat-sort-header-arrow"
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="seats-available">
                                <div className="mat-sort-header-container">
                                    <button className="mat-sort-header-button">
                                        <a>Seats Available</a>
                                        <Checkbox
                                            icon={<NorthIcon />}
                                            checkedIcon={<SouthIcon />}
                                            className="mat-sort-header-arrow"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bus-details-block">
                        <div className="bus-list-block">
                            <Card>
                                <CardContent>
                                    <div className="card-content-block">
                                        <div className="card-first-block">
                                            <div className="top-block">
                                                <div className="top-inner mb-8 ">
                                                    <div className="top-date-block display-date-list">
                                                        <p className="txt-color-maroon mb-0 mt-15">
                                                            30 Jan
                                                        </p>
                                                    </div>
                                                    <div className="display-grid mml-20 text-center mt-5">
                                                        <img src={round} alt />
                                                        <br />
                                                        <span className="border-vertical"></span>
                                                        <br />
                                                        <img src={map} alt />
                                                    </div>
                                                    <div className="time-block ">
                                                        <p className="f17 mb-0">
                                                            <strong>21:00</strong>
                                                        </p>
                                                        <small>Madurai</small>
                                                        <p className="f17 mb-7">
                                                            09:00Hrs
                                                        </p>
                                                        <small>Chennai</small>
                                                        <p className="f17 mb-0 mt-0">
                                                            <strong>06:00</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-sec-block">
                                            <div className="top-block">
                                                <div className="top-inner mb-8 ">
                                                    <div className="bus-block p-10">
                                                        <h4 className="mb-7 mt-2">
                                                            Aarah H4
                                                            <span className="font-weight-normal">AC Sleeper</span>
                                                            <span className="ml-5 font-weight-normal">(1+1)</span>
                                                        </h4>
                                                        <div className="top-inner  mb-8 light-color-text">
                                                            <div className="live-track mt-7">
                                                                <span className="ml-5">
                                                                    <LocationOnIcon />
                                                                    Live Tracking
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="top-inner  mb-8 light-color-text">
                                                            <div className="live-track mt-7">
                                                                <span className="ml-5">
                                                                    <StorefrontIcon />
                                                                    Rest Stop - 1
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="rating-block p-10">
                                                        <span className="start-rating">
                                                            <StarRateIcon className="star-icon" /> 0
                                                        </span>
                                                        <span className="ml-5">(0)</span>
                                                    </div>
                                                    <div className="price-block p-10 light-color-text">
                                                        <label>
                                                            <strong>₹999.00</strong>
                                                        </label>
                                                        <p>7 Seats Available</p>
                                                        <p>10 Window</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-content-block w-100 mb-7 ">
                                        <FeaturesPopup open={open} handleClose={handleDialogClose} title={featureTitle} data={dummyData} />
                                        <div className="botton-block view-bus-details align-right-contet mt-2">
                                            <a onClick={() => handleDialogOpen('Amenities')}>Amenities</a>
                                            <a onClick={() => handleDialogOpen('Boarding - Dropping Points')}>Boarding & Dropping Points</a>
                                            <a onClick={() => handleDialogOpen('Customer Reviews')}>Reviews</a>
                                            <a onClick={() => handleDialogOpen('Cancellation Policy')} className="last-details">Cancellation Policy</a>
                                        </div>
                                        <div className="view-seat mt-2 align-right-contet">
                                            {
                                                showSeats === false &&
                                                <Button onClick={handleOpenSeats}>
                                                    VIEW SEATS
                                                </Button>
                                            }
                                            {
                                                showSeats === true &&
                                                <Button onClick={handleCloseSeats}>
                                                    HIDE SEATS
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                    {
                                        showSeats === true && <ViewSeats />
                                    }

                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </div >

            </div >
        </div >
    )
}

export default BookingList;