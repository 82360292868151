import { useState } from 'react';
import { AppBar, Toolbar, Typography, makeStyles, Button } from '@material-ui/core';
import './Menubar.css';
import logo from '../../assets/logo.png';
import Signup from '../Signup/Signup';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center"
    },
    minHeight: {
        minHeight: "39px !important",
        height: '49px'
    },
    fontClass: {
        fontWeight: '500',
        fontSize: '14px'
    }
}));

const Menubar = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(0)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const handleTabIndex = (tabIndex) => {
        console.log(tabIndex)
        setActiveTabIndex(tabIndex);
    }
    console.log(activeTabIndex)
    return (
        <AppBar position='static' color='transparent' elevation={0} >
            <Signup handleOpen={open} handleClose={handleClose} tabIndex={activeTabIndex}/>
            <Toolbar className={classes.minHeight} classes={{ root: classes.root }} disableGutters={true}>
                <div>
                    <a>
                        <img src={logo} alt className='card-image' />
                    </a>
                </div>
                <Typography className='box' classes={{root: classes.fontClass}}>
                    
                        <div className='tickets-block'>
                            <a>
                                <span>Print Tickets</span>
                            </a>
                        </div >

                        <div className='tickets-block'>
                            <a>
                                <span>Cancelled Tickets</span>
                            </a>
                        </div>
                        <div className='tickets-block'>
                            <a>
                                <span></span>
                            </a>
                        </div>
                    
                </Typography>
                <div className='login-block'>
                    <span>
                        <Button disableFocusRipple={true} onClick={
                            ()=>{
                                handleOpen(); 
                                handleTabIndex(0);
                            }}>Login</Button>
                        <Button disableFocusRipple={true} onClick={
                            ()=>{
                                handleOpen(); 
                                handleTabIndex(1);
                            }}>Sign Up</Button>
                    </span>
                </div>
            </Toolbar>
        </AppBar>
    )
}

export default Menubar;