import SearchForm from "../SearchForm/SearchForm";
import Banner from '../Banner/Banner';
import Features from '../Features/Features';

const HomePage = () => {
    return(
        <div>
            <SearchForm/>
            <Banner/>
            <Features />
        </div>
        
    )
}

export default HomePage;